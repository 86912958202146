import React, { useMemo, useCallback } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';

import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import OrderVenueTimeSlots from '../../Order/Components/OrderVenueTimeSlots';

const VenueBookingCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const setTimeSlotsCart = useCallback((selected, slot) => {

        const slots: any = [];

        if (selected && selected.length) {

            selected.map((key) => {
                let keys = key.split('.');

                slots.push(keys[1]);

                return null;
            })

        }

        form.handleChange({ name: "slots", value: slots });

        form.handleChange({ name: "startTime", value: slots[0] });

        form.handleChange({ name: "blockSize", value: slot.size });

    }, [form.values]);

    return useMemo(() => (

        <Panel
            heading={"Booking Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 12, xs: 24 }}>

                        <FieldDate
                            form={form}
                            label={"Date"}
                            name={"date"}
                            required={true}
                            defaultValue={form.defaults.date}
                        />

                    </Col>

                    <Col
                        col={{ md: 24 }}
                        key={form.values.court.operatorId}
                    >

                        <FieldHidden
                            form={form}
                            name={"slots"}
                            defaultValue={form.values.slot}
                        />

                        <FieldHidden
                            form={form}
                            name={"startTime"}
                            defaultValue={form.values.startTime}
                        />
                        <FieldHidden
                            form={form}
                            name={"blockSize"}
                            defaultValue={form.values.blockSize}
                        />

                        <OrderVenueTimeSlots
                            operatorId={form.values.court.operatorId}
                            courtId={form.values.courtId}
                            date={form.values.date}
                            onChange={setTimeSlotsCart}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default VenueBookingCreateEditForm;
